import { ToastProps } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/toast'

const { toast } = createStandaloneToast()

interface IToastProps extends ToastProps {}

export function Toast({ ...rest }: IToastProps) {
  toast({
    isClosable: true,
    duration: 5000,
    position: 'bottom-right',
    variant: 'solid',
    ...rest
  })
}

export function ToastMultiple(dataErrors: any, { ...args }: ToastProps) {
  for (let k in dataErrors) {
    if (dataErrors.hasOwnProperty(k)) {
      Toast({ description: `${dataErrors[k].msg}`, ...args })
    }
  }
}
