import Meta from '@/component/Meta'
import { DankiCodeAI, Google, Lock, LogoWhite, Mail } from '@/component/Icon'
import {
  Flex,
  Text,
  Button,
  Link,
  Divider,
  Image,
  HStack
} from '@chakra-ui/react'
import Head from 'next/head'
import { Input } from '@/component/Form/Input'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { GetServerSideProps } from 'next'
import NextLink from 'next/link'
import { signIn } from 'next-auth/react'
import { Toast } from '@/utils/toast'
import { Subdomain } from '@/services/subdomain'
import { getServerSession } from 'next-auth'
import { authOptions } from './api/auth/[...nextauth]'

const FormSchema = z.object({
  email: z
    .string()
    .nonempty('Campo obrigatório')
    .email('E-mail inválido')
    .max(100, 'Máximo de 100 caracteres'),
  password: z
    .string()
    .nonempty('Campo obrigatório')
    .min(8, 'A senha deve conter pelo menos 8 caracteres')
    .max(100, 'Máximo de 100 caracteres')
})

type FormDataProps = z.infer<typeof FormSchema>

interface Props {
  subdomain: {
    sub: string | null
    image: string
    logo: string
  } | null
}

export default function Login({ subdomain }: Props) {
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingAccount, setIsLoadingAccount] = useState(false)
  const [initialEmail, setInitialEmail] = useState<string | undefined>()
  const query = router.query as Record<'callbackUrl' | 'error', string>

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<FormDataProps>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: initialEmail
    }
  })

  const handleLogin = async (values: FormDataProps) => {
    try {
      setIsLoadingAccount(true)

      let res = await signIn('credentials', {
        email: values.email,
        password: values.password,
        callbackUrl: query?.callbackUrl ?? `/dashboard`,
        redirect: false
      })

      if (res?.ok) {
        reset()
        Toast({
          title: 'Bem vindo(a)!',
          status: 'success'
        })
        if (subdomain) {
          await router.push(`/chat`)
        } else {
          await router.push(`${res.url}`)
        }
      } else {
        reset({ password: '' })
        return Toast({
          title: 'Acesso a conta',
          description:
            'Não foi possível acessar a sua conta, verifique os dados e acesso e tente novamente.',
          status: 'warning'
        })
      }
    } catch (e) {
      // console.log(e)
      Toast({
        title: 'Acesso a conta',
        description:
          'Parece que houve uma falha ao tentar acessar sua conta, tente novamente em alguns minutos.',
        status: 'error'
      })
    } finally {
      setIsLoadingAccount(false)
    }
  }

  const handleSignInWithGoogle = async () => {
    try {
      setIsLoading(true)
      await router.push(`${process.env.NEXT_PUBLIC_URL_API}/auth/login-google`)
    } catch (e) {
      //
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const email = router.query.email as string | undefined
    if (email) {
      setInitialEmail(email)
      setValue('email', email)
    }
  }, [router.query, setValue])

  return (
    <>
      <Head>
        <title>GPT Max - Acesse sua conta</title>
        <Meta
          image={`https://d1k1f4n2h095ym.cloudfront.net/filters:quality(42)/mockup.png`}
          pageTitle={'Crie seu próprio chat com nossa IA'}
          siteName={'GPT Max'}
          description={
            'Acesse sua conta no GPT Max e comece hoje mesmo a criar chats personalizados para o seu negócio.'
          }
          keywords={
            'Conversação Personalizada, Chat GPT Max, Interação Inteligente, Recursos Avançados, Upload de Arquivos, Orientação por Textos, Controle Total, Aprendizado de IA.'
          }
          follow
          canonical={'/login'}
        />
      </Head>
      <Flex flexDirection={'column'} align={'stretch'}>
        <Flex
          as={'section'}
          h={'100vh'}
          w={'100%'}
          align={'center'}
          justify={'space-between'}
          gap={[0, '40px', null, '160px']}
        >
          <Flex flex={1} px={'20px'} align={'center'} justify={'center'}>
            <Flex
              maxW={400}
              w={'100%'}
              flexDirection={'column'}
              gap={'32px'}
              align={'flex-start'}
              py={'32px'}
            >
              {subdomain ? (
                <Flex>
                  <Image src={subdomain.logo} width={'256px'} />
                </Flex>
              ) : (
                <Link href={'/'}>
                  <LogoWhite w={'124px'} h={'42px'} />
                </Link>
              )}
              <Text
                as={'h1'}
                fontWeight={'bold'}
                fontSize={['2xl', '2xl']}
                mt={'8px'}
              >
                Acesse sua conta
              </Text>
              <Flex
                w={'100%'}
                align={'stretch'}
                gap={'24px'}
                flexDirection={'column'}
                as={'form'}
                onSubmit={handleSubmit(handleLogin)}
              >
                <Input
                  type={'email'}
                  leftElement={<Mail w={'22px'} h={'22px'} />}
                  placeholder={'E-mail'}
                  {...register('email')}
                  error={errors.email}
                />
                <Input
                  type={'password'}
                  securityEntry
                  placeholder={'Senha'}
                  leftElement={<Lock w={'22px'} h={'22px'} />}
                  {...register('password')}
                  error={errors.password}
                />
                <Button
                  colorScheme={'green'}
                  size={'md'}
                  color={'grey.600'}
                  variant={'solid'}
                  type={'submit'}
                  isLoading={isSubmitting || isLoadingAccount}
                  loadingText={'Aguarde'}
                >
                  Entrar
                </Button>

                {!subdomain ? (
                  <>
                    <Flex w={'100%'} align={'center'} gap={'11px'}>
                      <Divider borderColor={'grey.400'} />{' '}
                      <Text color={'grey.400'}>ou</Text>{' '}
                      <Divider borderColor={'grey.400'} />
                    </Flex>
                    <Button
                      flex={1}
                      iconSpacing={'12px'}
                      bgColor={'white'}
                      variant={'outline'}
                      borderColor={'white'}
                      leftIcon={<Google w={'21px'} h={'21px'} />}
                      fontWeight={'semibold'}
                      color={'gray.900'}
                      _hover={{
                        bgColor: 'transparent',
                        color: 'white'
                      }}
                      isLoading={isLoading}
                      onClick={handleSignInWithGoogle}
                    >
                      Usar conta do Google
                    </Button>
                  </>
                ) : null}
              </Flex>
              <Flex
                w={'100%'}
                fontSize={'sm'}
                justify={'center'}
                align={'center'}
                // gap={'4px'}
              >
                <NextLink href={'/recovery-password'} passHref>
                  <Text color={'green.300'} fontWeight={'bold'}>
                    Esqueceu a senha?
                  </Text>
                </NextLink>
              </Flex>
              {!subdomain ? (
                <HStack justify={'center'} w={'full'}>
                  <Text color={'grey.200'} fontSize={'sm'}>
                    Ainda não tem conta?
                  </Text>
                  <NextLink href={'/register'} passHref>
                    <Text
                      color={'green.300'}
                      fontWeight={'bold'}
                      fontSize={'sm'}
                    >
                      Crie agora
                    </Text>
                  </NextLink>
                </HStack>
              ) : (
                <Flex
                  w={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDir={'column'}
                >
                  <Text color={'grey.300'}>Powered by</Text>{' '}
                  <Image
                    h={8}
                    src="https://d1k1f4n2h095ym.cloudfront.net/Logo-Abstrato-AI-white.png"
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex
            w={'100%'}
            display={['none', null, 'flex']}
            maxW={[0, '920px']}
            bgImage={
              subdomain
                ? subdomain.image
                : 'https://d1k1f4n2h095ym.cloudfront.net/bg-login.jpg'
            }
            bgRepeat={'no-repeat'}
            bgPosition={'center'}
            bgSize={'cover'}
            h={'100%'}
            flex={1}
          ></Flex>
        </Flex>
      </Flex>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getServerSession(context.req, context.res, authOptions)
  if (session) {
    return {
      props: {},
      redirect: {
        destination: '/dashboard'
      }
    }
  }

  const subdomain = await Subdomain(context.req)

  return {
    props: {
      subdomain
    }
  }
}
